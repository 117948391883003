<template>
  <v-text-field
    ref="paidValueInput"
    outlined
    v-model.number="value"
    :rules="[rules.required, rules.money, rules.lowerThan(totalGrossValue), rules.greaterThan(-1)]"
    validate-on-blur
    placeholder="Wprowadź zapłaconą kwotę"
    label="Rozliczono [zł]"
    type="number"
    @input="setValue"
    @wheel="$event.target.blur()"
  />
</template>

<script>
import rules from '../../../utils/validators'
import { parseAsBasicUnit } from '../../../utils'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    rules,
    value: null
  }),
  computed: {
    totalGrossValue () {
      return Math.ceil(this.data.payment?.totalGrossValue / 100) + 1
    }
  },
  mounted () {
    this.value = this.data.payment?.paidValue / 100
    this.$nextTick().then(() => {
      this.$refs.paidValueInput.focus()
    })
  },
  methods: {
    setValue (value) {
      this.$emit('update', { paidValue: parseAsBasicUnit(value) })
    },
  }
}
</script>
